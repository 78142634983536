import Loader from '@hiredigital/ui/Loader';
import PropTypes from 'prop-types';

const LoadingArea = ({ height, style }) => (
  <div
    style={{
      height: `${height}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...style,
    }}>
    <Loader color={Loader.Color.BLUE} type={Loader.Type.FULL} />
  </div>
);

LoadingArea.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export default LoadingArea;
